/** @type {import('@sveltejs/kit').HandleClientError} */
export function handleError({ error, event }) {
	// example integration with https://sentry.io/

	console.log('ERROR HAS HAPPENEND', error);

	return {
		input: {
			error
		}
	};
}
